// @flow

import * as React from 'react';
import Cookies from 'js-cookie';
import EndScreen from './EndScreen';
import { md5 } from 'md5js';
import type { Props } from './types';
const MAX_PROGRESS_ITEMS = 500;
const PROGRESS_RESET_THRESH  = 98;

type State = {
  modalVis: boolean;
  url: string;
  store: string;
  ended: boolean;
}
export default class Player extends React.Component<Props, State> {
  npoPlayer: any;
  id: string;
  playerId: string;
  node: HTMLElement;
  setupParams: Object;
  modalMsg: string;
  nodalLink: string;
  modalLinkAndroid: string;
  modalLinkIOS: string;
  totalDuration: number | null;
  openAppLink: string;

  constructor(props: Props): void {
    super(props);
    this.state = {
      modalVis: false,
      url: '',
      store: '',
      ended: false
    };
    this.modalLinkAndroid = 'https://play.google.com/store/apps/details?id=nl.npozapp';
    this.modalLinkIOS = 'https://itunes.apple.com/nl/app/npo-zapp/id924855073?mt=8';
    this.openAppLink = `https://openapp.zapp.nl/video/${this.props.mid}`;
    this.modalMsg = 'Deze video werkt hier helaas niet. Klik de link om de video in de app te bekijken.';
    this.totalDuration = null;
    const ccm = window.ccm;
    const adConsent = ccm ? ccm.getCategoryConsent('advertising') : false;
    this.setupParams = {
      autoplay: this.props.autoplay,
      placeholder: this.props.placeholder,
      noAds: this.props.noAds,
      endscreen: this.props.endscreen,
      recommendations: this.props.recommendations,
      moreButton: this.props.moreButton,
      nextEpisode: this.props.nextEpisode,
      hasSettings: this.props.hasSettings,
      sterReferralUrl: this.props.sterReferralUrl,
      sterIdentifier: this.props.sterIdentifier,
      pageUrl: this.props.pageUrl,
      hasAdConsent: adConsent,
      trackProgress: this.props.trackProgress,
      chromecast: {
        enabled: 1,
        castId: window.chromecastId || ""
      }
    };

    this.id = `player-id-${md5(JSON.stringify(this.setupParams) + this.props.mid)}`;
    this.playerId = `${this.id}-iframe`;

    this.setupParams.id = this.playerId
    this.setupParams.elementId = this.id

  }

  getProgress = (): number => {
    const allProgress = localStorage.getItem('zapp_progress');
    if (allProgress) {
      const currentProgress = JSON.parse(allProgress).find(item => item.mid === this.props.mid);
      if (currentProgress) {
        return currentProgress.ratio >= PROGRESS_RESET_THRESH ? 0 : currentProgress.progress;
      }
    }
    return 0;
  }

  percToSec = (perc: number) : number => {
    if (this.totalDuration) {
      return (perc / 100) * this.totalDuration;
    }
    return 0.0;
  }

  secToPerc = (duration: number): number => {
    if (this.totalDuration) {
      return (100 / this.totalDuration) * duration;
    }
    return 0.0;
  }


  saveProgress = (progress: number): void => {
    if (!progress) return;
    const allProgress = localStorage.getItem('zapp_progress');
    // Get progress from localStorage entry or create array
    const progressDb = allProgress ? JSON.parse(allProgress) : [];
    const progressObject = {
      mid: this.props.mid,
      progress: progress.toFixed(3),
      ratio: this.secToPerc(progress).toFixed(3)
    };
    const foundObject = progressDb.find(item => item.mid === this.props.mid);
    if (foundObject) { // entry for this mid is found
      // If the progress is within a rounded margin of the found progress, do nothing
      if (Math.round(parseFloat(foundObject.progress)) === Math.round(progress)) return;
      const index = progressDb.findIndex(item => item.mid === this.props.mid);
      // overwrite entry with new duration
      progressDb[index] = progressObject;
    } else {
      // no mid entry is found
      if (progressDb.length > MAX_PROGRESS_ITEMS - 1) {
        // remove the extra entries in the list
        progressDb.splice(0, progressDb.length - MAX_PROGRESS_ITEMS);
      }
      // Add a new duration entry at the end of the list
      progressDb.push(progressObject);
    }
    try {
      // save the list
      localStorage.setItem('zapp_progress', JSON.stringify(progressDb));
    } catch (e) {
      throw e;
    }
  }

  handleDrm = (actualEvent: any): void => {
    if (window.navigator.userAgent.indexOf('iPhone') > -1 || window.navigator.userAgent.indexOf('iPad') > -1) {
      this.setState({ modalVis: true, url: this.modalLinkIOS, store: 'App Store' });
    } else if (window.navigator.userAgent.indexOf('SamsungBrowser') > -1 || window.navigator.userAgent.indexOf('Chrome') > -1) {
      this.setState({ modalVis: true, url: this.modalLinkAndroid, store: 'Play Store' });
    }
  }

  handleError = (actualEvent: any): void => {
    if (window.navigator.userAgent.indexOf('iPhone') > -1 || window.navigator.userAgent.indexOf('iPad') > -1) {
      this.setState({ modalVis: true, url: this.modalLinkIOS, store: 'App Store' });
    } else if (/android/i.test(window.navigator.userAgent)) {
      this.setState({ modalVis: true, url: this.modalLinkAndroid, store: 'Play Store' });
    }
  }

  handleDuration = (actualEvent: any): void => {
    if (actualEvent.to > 0) {
      this.totalDuration = actualEvent.to;
    }
  }

  handleProgress = (actualEvent: any): void => {
    if (actualEvent.time > 0) {
      this.saveProgress(actualEvent.time);
    }
  }

  recommendation = (actualEvent: any): void => {
    window.location.href = `/mid/${actualEvent.video.id}`;
  }

  setSiteId = (actualEvent: any): void => {
    const userId = Cookies.getJSON('atuserid').val;
    const iframe = ((document.getElementById(`iframe-${this.props.mid}`) : any) : HTMLIFrameElement);
    iframe.contentWindow.postMessage(JSON.stringify({event: 'NPO_SET_SMARTTAG_USER_ID', userId: userId}), '*');
  }

  loadMid = async (): Promise<void> => {

    const options = {
      endpoint: `https://${window.playerGlobals.subdomain}.npoplayer.nl`,
      startOffset: this.getProgress(),
    };

    const response = await fetch('/api/v5/player_tokens', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ payload: this.props.playerTokenPayload }),
    });

    if (!response.ok) {
      // eslint-disable-next-line
      console.error('Failed to fetch JWT');
      return;
    }

    const data = await response.json();
    const jwt = data.token;

    this.npoPlayer.loadStream(jwt, options);
  }

  componentDidMount(): void {

    const container = document.getElementById(this.id);

    const npotag = {
      brand: window.playerGlobals.npotagBrand,
      brand_id: window.playerGlobals.npotagBrandId,
      platform: window.playerGlobals.npotagPlatform,
      platform_version: window.playerGlobals.npotagPlatformVersion,
    };

    const playerConfig = {
      key: window.playerGlobals.bitmovinKey,
      adaptation: {
        desktop: { preload: false },
        mobile: { preload: false }
      },
      analytics: {
        key: window.playerGlobals.bitmovinAnalyticsKey
      },
      playback: {
        autoplay: this.setupParams.autoplay,
      },
    };

    // eslint-disable-next-line
    this.npoPlayer = new window.NpoPlayer.default(container, playerConfig, npotag);

    this.npoPlayer.player.on('timechanged', this.handleProgress);
    this.npoPlayer.player.on('durationchanged', this.handleDuration);
    this.npoPlayer.player.on('ready', () => { this.totalDuration = this.npoPlayer.player.getDuration(); });
    this.npoPlayer.player.on('playbackfinished', () => {
      this.setState({ended: true});
      if (this.props.onComplete) {
        this.props.onComplete();
      }
    });

    this.loadMid()
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.mid !== prevProps.mid) {
      this.loadMid()
    }
  }

  componentWillUnmount(): void {
    this.npoPlayer.destroy()
  }

  render(): any {
    return (
      <React.Fragment>
        {this.state.modalVis &&
          (<div className="player__store-modal" >
            <p>{ this.modalMsg }</p>
            <br />
            <p>
              <a href={this.openAppLink}>{this.openAppLink}</a>
            </p>
          </div>)
        }
        {this.state.ended &&
          <EndScreen {...this.props} />
        }
        <div className="player__body" id={this.id} />
      </React.Fragment>
    );
  }
}
